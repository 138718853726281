<template>
	<div class="header">
		<div class="logo">
			<van-image width="100" height="30" :src="logoSrc" class="image" />
		</div>
    <div class="banner">
      <van-image width="100%" height="100%" :src="bannerSrc" />
    </div>
	</div>
</template>

<script>
	export default {
		name: 'LogoBanner',
		data() {
			return {
				logoSrc: require('@/assets/logo.png'),
				bannerSrc: require('@/assets/banner.png')
			}
		}
	}
</script>

<style lang="less" scoped>
	.logo {
		width: 100%;
		height: 64px;
		background: #051232;

		.image {
			margin: 15px;
		}
	}
</style>
