<template>
  <div class="home">
    <logo-banner></logo-banner>
    <home-tabs></home-tabs>
    <footer-text></footer-text>
  </div>
</template>

<script>
import LogoBanner from '@/components/LogoBanner.vue'
import HomeTabs from '@/components/HomeTabs.vue'
import FooterText from '@/components/FooterText.vue'
export default {
  name: 'Home',
  components: { LogoBanner, HomeTabs, FooterText }
}
</script>
