<template>
  <div class="tabs container">
    <van-tabs v-model="active" color="#0092F7" title-active-color="#0092F7">
      <van-tab title="参访上链">
        <visit-chain></visit-chain>
      </van-tab>
      <van-tab title="区块链体验">
        <try-blockchain></try-blockchain>
      </van-tab>
      <van-tab title="关于我们">
        <about-us></about-us>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import 'bootstrap/dist/css/bootstrap.min.css'
  import 'bootstrap/dist/js/bootstrap.min.js'
  import VisitChain from '@/components/VisitChain.vue'
  import TryBlockchain from '@/components/TryBlockchain.vue'
  import AboutUs from '@/components/AboutUs.vue'
  export default {
    name: 'HomeTabs',
    components: { VisitChain, TryBlockchain, AboutUs },
    data() {
      return {
        active: 0,
      }
    },
  }
</script>

<style lang="less">
  .van-tabs__wrap {
    border-bottom: 1px solid #ecf3f7;
  }
</style>
