<template>
	<div class="chain">
		<van-image :src="cardSrc" />
		<div class="explain">
			<div class="title">体验卡说明：</div>
			<p>安可链体验卡是安可链研院发行的安可区块链数字令牌存储卡，卡内存有66AKT（安可链数字令牌）可用于兑换区块链线上体验课程。</p>
			<p>使用方法：点击下方“立即兑换”--刮开卡片背面涂层码--按要求输入12位汉字--确认后即可体验课程</p>
		</div>
		<div class="class">
			<div class="title">区块链体验课</div>
			<div class="box">
				<div class="class-img">
					<van-image width="125" height="125" :src="classSrc" />
				</div>
				<div class="class-tips">
					<div class="title">区块链技术与应用</div>
					<p class="tips">本课程主要讲解区块链的基本概念和实现原理，面向具有计算机相关...</p>
					<div class="token">
						<div class="price">
							<span style="font-size: 18px;">66</span>
							<span class="right">AKT</span>
						</div>
						<van-button round type="info" size="small" @click="clickExchange">立即兑换</van-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { Dialog } from 'vant'
	export default {
		name: 'TryBlockchain',
		data() {
			return {
				cardSrc: require('@/assets/card.png'),
				classSrc: require('@/assets/class.png'),
				showDialog: false
			}
		},
		methods: {
			clickExchange() {
				Dialog.alert({
					title: '兑换体验课',
					message: '系统建设中，敬请期待'
				}).then(() => {})
			}
		}
	}
</script>

<style lang="less">
	.van-button__text {
		margin: 0 20px;
	}
</style>

<style lang="less" scoped>
	.chain {
		padding: 20px;
		text-align: center;
		
		.card {
			width: 100%;
			text-align: center;
			border: 1px dashed #a0a0a0;
		}

		.explain {
			font-size: 12px;
			color: #586b9a;
			font-weight: 500;
			text-align: justify;
			margin: 14px 10px 20px;

			.title {
				font-size: 14px;
			}
		}

		.class {
			.title {
				font-weight: 500;
			}

			.box {
				display: flex;
				width: 100%;
				height: 125px;
				margin-top: 20px;

				.class-tips {
					padding: 10px 0 10px 12px;

					.tips {
						font-size: 12px;
						margin: 10px 0;
					}

					.token {
						display: flex;
						align-items: center;
						justify-content: space-between;

						.price {
							color: #f46b2f;

							.right {
								font-size: 12px;
								margin-left: 5px;
							}
						}
					}
				}
			}
		}
	}
</style>
