<template>
	<div class="about">
		<div class="title">山东安可区块链产业发展研究院</div>
		<div class="attention">点击关注“安可链研院”公众号</div>
		<p class="main-text">
			<b>山东安可区块链产业发展研究院</b>
			是政府引导，产研结合的新型研发机构。由省工信厅作为业务指导单位，省网信办审议批准，市中区政府重点扶持，以航天信息联合中国计算机学会区块链专委会共建，融合华耀资本成立的非营利、专业性的法人研究机构。
			现以融合泉链、福链等省内外22家企业形成共同体生态，致力于区块链领域的技术研究、行业分析、应用创新、产业转化、顾问咨询、人才培养、双招双引、孵化加速等工作。
			链研院立足济南，服务山东，面向全国，助力区块链产业健康可持续发展。
		</p>
		<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			<van-swipe-item>
				<van-image  width="100%" height="100%" :src="require('@/assets/swiper-1.jpg')"></van-image>
			</van-swipe-item>
			<van-swipe-item>
				<van-image  width="100%" height="100%" :src="require('@/assets/swiper-2.jpg')"></van-image>
			</van-swipe-item>
			<van-swipe-item>
				<van-image  width="100%" height="100%" :src="require('@/assets/swiper-3.jpg')"></van-image>
			</van-swipe-item>
			<van-swipe-item>
				<van-image  width="100%" height="100%" :src="require('@/assets/swiper-4.jpg')"></van-image>
			</van-swipe-item>
		</van-swipe>
	</div>
</template>

<script>
	export default {
		name: 'AboutUs'
	}
</script>

<style lang="less" scoped>
	.about {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 20px;

		.title {
			font-weight: 700;
			color: #051232;
			margin-bottom: 20px;
		}

		.attention {
			font-size: 14px;
			font-weight: bold;
			color: #0092f7;
		}

		.main-text {
			text-align: justify;
		}

		.my-swipe {
			width: 100%;
		}
	}
</style>
