<template>
  <div class="chain">
    <div class="header">
      <div class="title">
        {{ visitData.visit_title }}
      </div>
      <div class="interview-data">
        <span class="left-text">参访日期：</span>
        <span>{{ visitData.visit_date }}</span>
      </div>
      <!-- <div class="chain-data">
				<span class="left-text">上链时间：</span>
				<span>2020.01.01 12:00:00</span>
			</div>
			<div class="block-height">
				<span class="left-text">区块高度：</span>
				<span class="link-color">168453</span>
			</div>
			<div class="hash-code" style="height: 34px;">
				<span class="left-text" style="float: left;">Hash：</span>
				<span class="link-color">34fh34hj4f03h09g8f3495h67ik56u54ytg60789l9k5jh5yk786y4thj567</span>
			</div> -->
    </div>
    <p class="main">
      {{ visitData.visit_intro }}
    </p>
    <van-swipe class="my-swipe" :autoplay="3000" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in visitData.gallery_images" :key="index">
        <van-image width="100%" height="100%" :src="'https://ankechain.com/visit' + item.image.meta.download_url"></van-image>
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>
  export default {
    name: 'VisitChain',
    data() {
      return {
        visitData: {},
      }
    },
    mounted() {
      // console.log(this.$route);
      // console.log(this.$route.query);
      // console.log(window.location.pathname);
      
      // this.getUserVisitHistory(this.$route.query.timestamp)
      this.getUserVisitHistory(window.location.pathname.replace('/', ''))
      // this.getUserVisitHistory('GE3DANJSGMYTENRZGAYDA')
    },
    methods: {
      getUserVisitHistory(timestamp) {
        const url = `https://www.ankechain.com/visit/api/v2/pages/?type=visit.VisitInfoPage&visit_time=${timestamp}&fields=_,visit_date,start_time,end_time,visit_title,visit_intro,gallery_images(_,image,caption)`
        this.$axios.get(url).then(res => {
          this.visitData = res.data.items[0]
        })
      },
    },
  }
</script>

<style lang="less" scoped>
  .chain {
    padding: 20px;

    .header {
      font-size: 12px;
      color: #586b9a;
      font-weight: 500;

      .title {
        font-size: 16px;
        color: #000;
        margin-bottom: 16px;
      }

      .left-text {
        display: inline-block;
        height: 34px;
        width: 60px;
      }

      .link-color {
        color: #0092f7;
        word-break: break-all;
        border-bottom: 1px solid #0092f7;
      }
    }

    .main {
      font-size: 14px;
      font-weight: 500;
      color: #051232;
      line-height: 21px;
      margin: 16px 0;
    }

    .my-swipe {
      display: flex;
      align-items: center;
      width: 100%;
    }
  }
</style>
