<template>
  <div class="footer">
    <div class="title">山东安可区块链产业发展研究院</div>
    <div class="tips">鲁ICP备2020044743号</div>
  </div>
</template>

<script>
export default {
  name: 'FooterText'
}
</script>

<style lang="less" scoped>
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 60px;
  font-weight: 500;
  background: #051232;

  .title {
    color: #fff;
    font-size: 14px;
  }

  .tips {
    color: #97ACDE;
    font-size: 10px;
  }
}
</style>
